<div id="initial-information" class="initial-information-content">
  <h1>
    {{ 'rspInitialInformationTitle' | translate }}
  </h1>

  <div id="addAssessment" *ngIf="internalPrepareAllowed" class="card panel panel-info">
    <div class="card-header panel-heading">
      {{ 'rspInitialInformationPrepareTitle' | translate }}
    </div>
    <div class="card-body">
      {{ 'rspInitialInformationChoose' | translate }}
      <div class="product-list">
        <div *ngFor="let productData of kbProductList.products">
          <input
            type="checkbox"
            value="{{ productData.id }}"
            id="{{ productData.id }}"
            (change)="validateProductSelection()"
            [disabled]="!isMultipleInsuredPersonsAllowed && productData.notAvailable"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="productData.checked"
          />
          <label for="{{ productData.id }}">{{ productData.caption }} </label>
          <ng-container *ngIf="productData.checked">
            <div class="riders" *ngFor="let rider of productData.riders">
              <input
                type="checkbox"
                value="{{ rider.id }}"
                id="{{ rider.id }}"
                (change)="validateProductSelection()"
                [disabled]="!isMultipleInsuredPersonsAllowed && rider.notAvailable"
                [(ngModel)]="rider.checked"
                [ngModelOptions]="{ standalone: true }"
              />
              <label for="{{ rider.id }}">{{ rider.caption }} </label>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="input-group mt-2 insured-person-name" *ngIf="isMultipleInsuredPersonsAllowed">
        <span class="input-group-text" id="insured-person-name-label">{{ 'rspInitialInformationInsuredPersonNameLabel' | translate }}</span>
        <input
          type="text"
          id="insured-person-name"
          class="form-control"
          aria-describedby="insured-person-name-label"
          maxlength="100"
          [(ngModel)]="insuredPersonNameInput"
        />
      </div>
    </div>
    <div class="card-footer">
      <button id="prepare-assessment" [disabled]="!addAssessmentEnabled" class="btn btn-success" (click)="prepareAssessment()">
        {{ 'rspInitialInformationPrepare' | translate }}
      </button>
    </div>
  </div>

  <ng-container *ngIf="userContextService.currentUserContext$ | async; let userContext">
    <div class="risk-assessment-panel card" *ngFor="let assessmentWrapper of userContext?.assessmentWrappers">
      <div class="panel panel-primary" id="assessment-{{ assessmentWrapper.id }}">
        <div class="card-header bg-primary panel-heading">
          <ng-container *ngIf="assessmentWrapper.insuredPersonFullName"> {{ assessmentWrapper.insuredPersonFullName }} - </ng-container>
          {{ 'rspInitialInformationPanelTitle' | translate }}
          {{ assessmentWrapper.state | translate }}
        </div>
        <div class="card-body">
          <p>
            {{
              'rspInitialInformationCreated'
                | translate
                  : {
                      created: assessmentWrapper.created | date: ('rspDateFormat' | translate),
                    }
            }}
          </p>
          <div *ngFor="let productView of assessmentWrapper.productViews">
            <div class="list-group-item well product-info">
              <p>
                <strong>
                  {{ 'rspInitialInformationProducts' | translate }}
                </strong>
                {{ productView.name }}
              </p>
              <p *ngIf="!hideRiders && productView.riders && productView.riders.length">
                <strong>
                  {{ 'rspInitialInformationRiders' | translate }}
                </strong>
                {{ productView.riders.join(',') }}
              </p>
            </div>
          </div>
          <div class="list-group-item well assessment-status">
            <ng-container *ngIf="!assessmentWrapper.assessmentId">
              {{ 'rspInitialInformationNotStarted' | translate }}
            </ng-container>
            <ng-container *ngIf="assessmentWrapper.assessmentId"
              >{{
                isAssessed(assessmentWrapper)
                  ? ('rspInitialInformationCompletedStatus'
                    | translate
                      : {
                          lastModified: assessmentWrapper.lastModified | date: ('rspDateFormat' | translate),
                        })
                  : ('rspInitialInformationOngoingStatus'
                    | translate
                      : {
                          lastModified: assessmentWrapper.lastModified | date: ('rspDateFormat' | translate),
                        })
              }}
            </ng-container>
          </div>
        </div>
        <div class="card-footer">
          <button
            class="delete-assessment-button btn btn-secondary ms-1"
            (click)="deleteAssessment(assessmentWrapper.id)"
            *ngIf="!isAssessed(assessmentWrapper) && internalPrepareAllowed"
            type="button"
          >
            {{ 'rspButtonDelete' | translate }}
          </button>
          <button
            class="start-assessment-button btn btn-primary"
            (click)="starRestartOrInitializeAssessment(assessmentWrapper.id)"
            *ngIf="!isAssessed(assessmentWrapper)"
            type="button"
          >
            <ng-container *ngIf="!assessmentWrapper.assessmentId">
              {{ 'rspInitialInformationStart' | translate }}
            </ng-container>
            <ng-container *ngIf="assessmentWrapper.assessmentId">
              {{ 'rspInitialInformationContinue' | translate }}
            </ng-container>
          </button>
          <button
            class="view-result-button btn btn-primary"
            (click)="viewResult(assessmentWrapper.id)"
            *ngIf="isAssessed(assessmentWrapper)"
            type="button"
          >
            {{ 'rspInitialInformationViewResult' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #deleteAssessmentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'rspDeleteDialogTitle' | translate }}
    </h4>
    <button type="button" class="btn-close" [attr.aria-label]="'rspModalCloseButtonAriaLabel' | translate" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ 'rspDeleteDialogBody' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark modal-dismiss" (click)="modal.dismiss()">
      {{ 'rspLanguageWarningButtonCancel' | translate }}
    </button>
    <button type="button" class="btn btn-outline-dark modal-close" (click)="modal.close('delete')">
      {{ 'rspDeleteDialogDelete' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #restartAssessmentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="restart-assessment-title">
      {{ 'rspLanguageWarningTitle' | translate }}
    </h4>
    <button type="button" class="btn-close" [attr.aria-label]="'rspModalCloseButtonAriaLabel' | translate" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ 'rspLanguageWarningBody' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">
      {{ 'rspLanguageWarningButtonCancel' | translate }}
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('restart')">
      {{ 'rspLanguageWarningButtonAccept' | translate }}
    </button>
  </div>
</ng-template>
