<h1>
  {{ (!summaryMode ? 'rspAssessmentHeading' : 'rspReviewHeading') | translate }}
  <ng-container *ngIf="insuredPersonFullName">
    <br />
    {{ 'rspInsuredPersonText' | translate: { insuredPersonName: insuredPersonFullName } }}
  </ng-container>
</h1>
<form class="mx-1 rsp-assessment" [class.review-form]="summaryMode">
  <cep-web-questionnaire
    #questionnaireComponent
    *ngIf="activeAssessmentId"
    [assessmentId]="activeAssessmentId"
    [config]="questionnaireConfig"
    [summaryMode]="summaryMode"
    [language]="language"
    [translations]="translations"
    [paginationMode]="paginationMode"
    [activePageId]="activePageId"
    [arsp-containerpplication]="applicationData"
    [rtl]="isRtl"
    [authorizationHandler]="renewToken"
    (activePageIdChange)="activePageId = $event.detail"
    (notification)="onNotification($event.detail)"
    (initializedChange)="onQuestionnaireInit()"
    (assessmentFinished)="onAssessmentFinished()"
    (applicationChange)="applicationData = $event.detail"
    (summaryModeChange)="onSummaryModeChange($event.detail)"
  >
    <div slot="before">
      <cep-web-question-reminder [offset]="54"></cep-web-question-reminder>
      <cep-web-blocked-pagination-alert></cep-web-blocked-pagination-alert>
      <cep-web-breadcrumb-pagination></cep-web-breadcrumb-pagination>
      <cep-web-prev-next-pagination></cep-web-prev-next-pagination>
    </div>
    <div slot="after" class="d-flex justify-content-between overflow-x-auto rsp-assessment-bottom-navigation flex-column flex-sm-row">
      <div>
        <cep-web-full-page-pagination></cep-web-full-page-pagination>
        <cep-web-questionnaire-legend></cep-web-questionnaire-legend>
      </div>
      <div class="d-flex justify-content-between container-fluid p-0">
        <cep-web-summary-button [class.ms-auto]="!summaryMode"></cep-web-summary-button>
        <cep-web-submit-button *ngIf="summaryMode"></cep-web-submit-button>
      </div>
    </div>
  </cep-web-questionnaire>
</form>
