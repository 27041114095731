import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config-service/app-config.service';

const PLACE_HOLDER_CONFIGURATION_ID = ':configurationId';
const PLACE_HOLDER_LANG = ':lang';
const PLACE_HOLDER_ASSESSMENT_WRAPPER_ID = ':assessmentWrapperId';
const PLACE_HOLDER_ASSESSMENT_ID = ':assessmentId';
const PLACE_HOLDER_USER_CONTEXT_ID = ':userContextId';

@Injectable({
  providedIn: 'root',
})
export class UrlConfigService {
  baseContextPath: string = '';
  authServiceBasePath: string = '';
  themingServiceBasePath: string = '';

  public static joinPaths(...paths: string[]): string {
    let optionalStartingSlash = paths.length > 0 && paths[0] && paths[0].startsWith('/') ? '/' : '';
    return (
      optionalStartingSlash +
      paths
        .filter(s => s)
        .filter(s => s !== '/')
        .map(s => s.replace(/(^\/)|(\/$)/g, ''))
        .join('/')
    );
  }

  constructor(private appConfigService: AppConfigService) {
    this.baseContextPath = this.appConfigService.configData.api.urlPrefix;
    this.authServiceBasePath = UrlConfigService.joinPaths(this.baseContextPath, this.appConfigService.configData.api.authService.urlPrefix);
    this.themingServiceBasePath = UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.themingService.urlPrefix
    );
  }

  getBaseContextPath(): string {
    return this.baseContextPath;
  }

  getAuthServiceLoginUrl(configurationId: string, lang: string): string {
    return UrlConfigService.joinPaths(this.authServiceBasePath, this.appConfigService.configData.api.authService.loginPath)
      .replace(PLACE_HOLDER_CONFIGURATION_ID, configurationId)
      .replace(PLACE_HOLDER_LANG, lang);
  }

  getAuthServiceLogoutUrl(): string {
    return UrlConfigService.joinPaths(this.authServiceBasePath, this.appConfigService.configData.api.authService.logoutPath);
  }

  getAuthServiceSignUrl(assessmentWrapperId: string, lang: string): string {
    return UrlConfigService.joinPaths(this.authServiceBasePath, this.appConfigService.configData.api.authService.signPath)
      .replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId)
      .replace(PLACE_HOLDER_LANG, lang);
  }

  getAuthServiceVerifyUrl(assessmentId: string): string {
    return UrlConfigService.joinPaths(this.authServiceBasePath, this.appConfigService.configData.api.authService.verifyPath).replace(
      PLACE_HOLDER_ASSESSMENT_ID,
      assessmentId
    );
  }

  getAuthServiceTokenRefresh(assessmentId: string): string {
    return UrlConfigService.joinPaths(this.authServiceBasePath, this.appConfigService.configData.api.authService.tokenRefreshPath).replace(
      PLACE_HOLDER_ASSESSMENT_ID,
      assessmentId
    );
  }

  getAssessmentServiceBaseUrl(assessmentWrapperId?: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.assessmentService.urlPrefix.replace(
        PLACE_HOLDER_ASSESSMENT_WRAPPER_ID,
        assessmentWrapperId ? assessmentWrapperId : ''
      )
    );
  }

  getAssessmentServiceIsCompleteUrl(assessmentWrapperId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.assessmentService.urlPrefix,
      this.appConfigService.configData.api.assessmentService.isCompletePath
    ).replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId);
  }

  getAssessmentServiceInitUrl(assessmentWrapperId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.assessmentService.urlPrefix,
      this.appConfigService.configData.api.assessmentService.initPath
    ).replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId);
  }

  getAssessmentServiceProductViewUrl(assessmentWrapperId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.assessmentService.urlPrefix,
      this.appConfigService.configData.api.assessmentService.productViewPath
    ).replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId);
  }

  getAssessmentServiceRestartUrl(assessmentWrapperId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.assessmentService.urlPrefix,
      this.appConfigService.configData.api.assessmentService.restartPath
    ).replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId);
  }

  getAssessmentServiceResultUrl(assessmentWrapperId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.assessmentService.urlPrefix,
      this.appConfigService.configData.api.assessmentService.resultPath
    ).replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId);
  }

  getAssessmentServiceConfirmUrl(assessmentWrapperId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.assessmentService.urlPrefix,
      this.appConfigService.configData.api.assessmentService.confirmPath
    ).replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId);
  }

  getAssessmentServiceProcessUrl(assessmentWrapperId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.assessmentService.urlPrefix,
      this.appConfigService.configData.api.assessmentService.processPath
    ).replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId);
  }

  getAssessmentServiceChangeStateUrl(assessmentWrapperId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.assessmentService.urlPrefix,
      this.appConfigService.configData.api.assessmentService.changeStatePath
    ).replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId);
  }

  getUserContextServiceBaseUrl(userContextId?: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.userContextService.urlPrefix,
      userContextId
    );
  }

  getUserContextServiceAuthenticatedUrl(): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.userContextService.urlPrefix,
      this.appConfigService.configData.api.userContextService.authenticatedPath
    );
  }

  getUserContextServiceConsentUrl(userContextId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.userContextService.urlPrefix,
      this.appConfigService.configData.api.userContextService.consentPath
    ).replace(PLACE_HOLDER_USER_CONTEXT_ID, userContextId);
  }

  getUserContextServiceChangeStateUrl(userContextId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.userContextService.urlPrefix,
      this.appConfigService.configData.api.userContextService.changeStatePath
    ).replace(PLACE_HOLDER_USER_CONTEXT_ID, userContextId);
  }

  getUserContextServiceInternalPrepareUrl(userContextId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.userContextService.urlPrefix,
      this.appConfigService.configData.api.userContextService.internalPrepare
    ).replace(PLACE_HOLDER_USER_CONTEXT_ID, userContextId);
  }

  getThemingServiceLeftLogoUrl(configurationId: string): string {
    return UrlConfigService.joinPaths(
      this.themingServiceBasePath,
      this.appConfigService.configData.api.themingService.logoPath.left
    ).replace(PLACE_HOLDER_CONFIGURATION_ID, configurationId);
  }

  getThemingServiceRightLogoUrl(configurationId: string): string {
    return UrlConfigService.joinPaths(
      this.themingServiceBasePath,
      this.appConfigService.configData.api.themingService.logoPath.right
    ).replace(PLACE_HOLDER_CONFIGURATION_ID, configurationId);
  }

  getThemingServiceFaviconUrl(configurationId: string): string {
    return this.appConfigService.configData.api.themingService.faviconPath
      ? UrlConfigService.joinPaths(this.themingServiceBasePath, this.appConfigService.configData.api.themingService.faviconPath).replace(
          PLACE_HOLDER_CONFIGURATION_ID,
          configurationId
        )
      : '';
  }

  getThemingServiceTranslationUrl(configurationId: string): string {
    return (
      UrlConfigService.joinPaths(this.themingServiceBasePath, this.appConfigService.configData.api.themingService.translationsPath).replace(
        PLACE_HOLDER_CONFIGURATION_ID,
        configurationId
      ) +
      // We need the trailing slash here to support ngx-translate http-loader
      '/'
    );
  }

  getThemingServiceLocaleUrl(): string {
    return UrlConfigService.joinPaths(this.themingServiceBasePath, this.appConfigService.configData.api.themingService.localePath);
  }

  getDocumentServiceDocUrl(assessmentWrapperId: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.documentService.urlPrefix,
      this.appConfigService.configData.api.documentService.docPath
    ).replace(PLACE_HOLDER_ASSESSMENT_WRAPPER_ID, assessmentWrapperId);
  }

  getResourceServiceProductSetupUrl(lang: string): string {
    return UrlConfigService.joinPaths(
      this.baseContextPath,
      this.appConfigService.configData.api.resourceService.urlPrefix,
      this.appConfigService.configData.api.resourceService.productSetupPath
    ).replace(PLACE_HOLDER_LANG, lang);
  }
}
