import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ConfigState } from '../../models/configuration.model';
import { FeatureToggles } from '../../models/feature-toggle';
import { CepTranslationModel } from '../../models/cep-translation.model';
import { CepConfigurationModel } from '../../models/cep-configuration.model';
import { catchError, map, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { CookieKey } from '../../models/cookie-key.model';
import { UrlConfigService } from '../url-config-service/url-config.service';

const PLACE_HOLDER_CONFIGURATION_ID = ':configurationId';

@Injectable()
export class AppConfigService {
  public version: string;
  public configData: ConfigState;
  public featureToggles: FeatureToggles;
  public configurationId: string;
  public cepTranslation: CepTranslationModel;
  public cepConfiguration: CepConfigurationModel;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    const urlSearchParams = new URLSearchParams(window.location.href.split('?')[1]);
    const urlSearchParamsCaseInsensitive = new URLSearchParams();
    urlSearchParams.forEach((value, key) => urlSearchParamsCaseInsensitive.append(key.toLowerCase(), value));

    if (urlSearchParamsCaseInsensitive.has('configurationid')) {
      this.configurationId = urlSearchParamsCaseInsensitive.get('configurationid');

      const cookieExpirationDate = new Date();
      cookieExpirationDate.setDate(cookieExpirationDate.getDate() + 10);
      this.cookieService.set(CookieKey.ConfigurationId, this.configurationId, {
        expires: cookieExpirationDate,
      });
    } else if (this.cookieService.get(CookieKey.ConfigurationId)) {
      this.configurationId = this.cookieService.get(CookieKey.ConfigurationId);
    }
  }

  load(): Observable<ConfigState> {
    let featureToggle: Observable<any> = this.http.get('resource/feature-toggles').pipe(
      catchError(val => {
        return of(val);
      }),
      tap((data: FeatureToggles) => {
        this.featureToggles = data;
      })
    );

    let cepTranslation: Observable<CepTranslationModel> = this.http.get<CepTranslationModel>('resource/cep-translations').pipe(
      catchError(val => {
        return of(val);
      }),
      tap(ctm => (this.cepTranslation = ctm))
    );

    let cepConfiguration: Observable<CepConfigurationModel> = this.http
      .get<CepConfigurationModel>('resource/configuration/cep-configuration.json')
      .pipe(
        catchError(val => {
          return of(val);
        }),
        tap(ccm => (this.cepConfiguration = ccm))
      );

    let rspConfiguration: Observable<ConfigState> = this.http.get('resource/configuration/rsp-configuration.json').pipe(
      catchError(val => {
        return of(val);
      }),
      tap((data: ConfigState) => {
        this.configData = data;

        if (!this.configurationId) {
          this.configurationId = data.defaultConfigurationId;
        }

        let customStyle = window.document.createElement('LINK');

        const cssUrl = UrlConfigService.joinPaths(
          data.api.urlPrefix,
          data.api.themingService.urlPrefix,
          data.api.themingService.cssPath
        ).replace(PLACE_HOLDER_CONFIGURATION_ID, this.configurationId);
        customStyle.setAttribute('href', cssUrl);
        customStyle.setAttribute('id', 'specificStyle');
        customStyle.setAttribute('rel', 'stylesheet');
        customStyle.setAttribute('type', 'text/css');
        window.document.getElementsByTagName('head')[0].appendChild(customStyle);

        return data;
      })
    );

    return forkJoin([featureToggle, cepTranslation, cepConfiguration, rspConfiguration]).pipe(map(configurations => configurations[3]));
  }

  public deleteStoredConfigurationId(configurationId: string): void {
    if (this.cookieService.check(CookieKey.ConfigurationId) && this.cookieService.get(CookieKey.ConfigurationId) === configurationId) {
      this.cookieService.delete(CookieKey.ConfigurationId);
      this.configurationId = undefined;
    }
  }
}
