export interface AssessmentWrapperModel {
  id: string;
  providedData: string;
  assessmentId: string;
  directUrl: string;
  assessmentLanguage: string;
  created: Date;
  lastModified: Date;
  confirmed: Date;
  state: AssessmentState;
  userContextId: string;
  insuredPersonFullName?: string;
  productViews?: AssessmentProductView[];
}

export enum AssessmentState {
  CREATED = 'CREATED',
  PREPARED = 'PREPARED',
  STARTED = 'STARTED',
  CONSENTED = 'CONSENTED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  SIGNED = 'SIGNED',
  PROCESSED_MI = 'PROCESSED_MI',
  PROCESSED = 'PROCESSED',
}

export interface AssessmentProductView {
  id: string;
  name: string;
  riders: any[];
}
