import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { DatePipe } from '@angular/common';
import { UserContext } from '../../models/user-context.model';
import { ProvidedData } from '../../models/provided-data.model';
import { KbProductList } from '../../models/kb-product-list.model';
import { SelectedProducts } from '../../models/selected-products.model';
import { UrlConfigService } from '../url-config-service/url-config.service';
import { InternalPrepareInformation } from '../../models/internal-prepare-information.model';

@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  private _currentUserContext: BehaviorSubject<UserContext> = new BehaviorSubject(null);
  readonly currentUserContext$: Observable<UserContext> = this._currentUserContext.asObservable();

  constructor(private http: HttpClient, private urlConfigService: UrlConfigService) {
    this.getAuthenticatedUser().subscribe(user => {
      this._currentUserContext.next(user);
    });
  }

  get loggedInUser(): UserContext {
    return this._currentUserContext.getValue();
  }

  get(userContextId?: string): Observable<UserContext> {
    return this.http
      .get<UserContext>(this.urlConfigService.getUserContextServiceBaseUrl(userContextId || ''))
      .pipe(tap(updatedUserContext => this._currentUserContext.next(updatedUserContext)));
  }

  internalPrepare(userContextId: string, productList: KbProductList, insuredPersonFullName?: string): Observable<UserContext> {
    const url: string = this.urlConfigService.getUserContextServiceInternalPrepareUrl(userContextId);

    let formattedDate: string = new DatePipe('en-US').transform(new Date(), 'YYYY-MM-dd');

    let products: SelectedProducts = {};
    for (let product of productList.products) {
      if (product.checked) {
        products[product.id] = { riders: {} };
        if (product.riders) {
          for (let r of product.riders) {
            if (r.checked) {
              products[product.id].riders[r.id] = {};
            }
          }
        }
      }
    }

    const providedData: ProvidedData = {
      source: { id: 'RSP', type: 'CustomerPortal', url: '#!/review' },
      products: products,
      questionnaire: {
        ApplicationDate: {
          answer: formattedDate,
        },
      },
    };
    const internalPrepareInformation: InternalPrepareInformation = {
      insuredPersonFullName,
      providedData,
    };

    return this.http
      .post<UserContext>(url, internalPrepareInformation)
      .pipe(tap((updatedUserContext: UserContext) => this._currentUserContext.next(updatedUserContext)));
  }

  getAuthenticatedUser(): Observable<UserContext> {
    return this.http
      .get<UserContext>(this.urlConfigService.getUserContextServiceAuthenticatedUrl())
      .pipe(tap(updatedUserContext => this._currentUserContext.next(updatedUserContext)));
  }

  giveConsent(userContextId: string): Observable<any> {
    return this.http.post(this.urlConfigService.getUserContextServiceConsentUrl(userContextId), undefined, { responseType: 'text' });
  }

  resetUserContext(): void {
    this._currentUserContext.next(null);
  }

  getInsuredPersonFullNameForAssessmentWrapper(assessmentWrapperId: string): string {
    const assessmentWrapper = this._currentUserContext.getValue().assessmentWrappers.find(wrapper => wrapper.id === assessmentWrapperId);

    return assessmentWrapper?.insuredPersonFullName;
  }
}
