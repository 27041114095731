import { Component } from '@angular/core';
import { UserContextService } from '../../services/user-context-service/user-context.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
})
export class ConsentComponent {
  hasGivenConsent: boolean = false;

  constructor(private userContextService: UserContextService, private router: Router) {}

  giveConsent(): void {
    this.userContextService
      .giveConsent(this.userContextService.loggedInUser?.id.toString())
      .subscribe(() => this.router.navigate(['/initial-information']));
  }
}
