<div class="flex-wrapper">
  <div class="center-page">
    <h1>
      {{ 'rspSigningTitle' | translate }}
      <ng-container *ngIf="insuredPersonFullName">
        <br />
        {{ 'rspInsuredPersonText' | translate: { insuredPersonName: insuredPersonFullName } }}
      </ng-container>
    </h1>
    <iframe *ngIf="iframeSrc" [src]="iframeSrc" id="signFrame" name="E-Ident" title="E-Ident"></iframe>
  </div>
</div>
