import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `
    <div class="modal-header">
      <div class="container modal-header-container">
        <div class="row">
          <div class="col-10 col-sm-11 modal-header-col-left">
            <h4 class="modal-title" id="modal-title">
              {{ 'rspSaveAndQuitDialogTitle' | translate }}
            </h4>
          </div>
          <div class="col-2 col-sm-1 modal-header-col-right">
            <button
              id="modalBtnCancelLogout"
              type="button"
              class="btn-close"
              [attr.aria-label]="'rspModalCloseButtonAriaLabel' | translate"
              (click)="activeModal.dismiss()"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <p class="modal-body" id="modal-body">
      {{ 'rspSaveAndQuitDialogBody' | translate }}
    </p>
    <div class="modal-footer">
      <button id="modalBtnSaveAndQuitAssessment" class="btn btn-outline-dark" type="button" (click)="activeModal.close('save')">
        {{ 'rspSaveAndQuitDialogSave' | translate }}
      </button>
      <button id="modalBtnSaveAndQuitDontSaveAssessment" class="btn btn-outline-dark" type="button" (click)="activeModal.close('delete')">
        {{ 'rspSaveAndQuitDialogDoNotSave' | translate }}
      </button>
    </div>
  `,
})
export class SaveAndQuitAssessmentModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
