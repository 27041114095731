<div class="flex-wrapper">
  <div class="center-page">
    <section id="result">
      <header>
        <h1>
          {{ 'rspResultHeading' | translate }}
          <ng-container *ngIf="insuredPersonFullName">
            <br />
            {{ 'rspInsuredPersonText' | translate: { insuredPersonName: insuredPersonFullName } }}
          </ng-container>
        </h1>
      </header>
      <div *ngIf="showResultToUser">
        <div class="result-item" *ngFor="let result of productResults">
          <section class="card">
            <header class="card-header">
              <h5 class="result-name card-title">
                {{ result.name }}
              </h5>
            </header>
            <section class="card-body">
              <section class="rating-item well-sm" *ngFor="let rating of result.ratings" [class]="'decision-' + rating.id">
                <h6 class="rating-caption">{{ rating.caption }}</h6>
                <div
                  class="rating-details"
                  *ngIf="rating.attributes.length > 0 && rating.id !== 'Accept' && showDetails(rating.attributes)"
                >
                  <div class="well">
                    <h6 class="card-subtitle">
                      {{ 'rspResultHeadingDecisionDetails' | translate }}
                    </h6>
                    <ul class="list-attributes">
                      <div class="attribute-item" *ngFor="let attribute of rating.attributes">
                        <!-- Text template -->
                        <li class="text-attr" [class]="attribute.id" *ngIf="isTextValueOnlyTemplate(rating.attributes, attribute)">
                          <em>{{ attribute.value }}</em>
                        </li>

                        <!-- Text and caption template -->
                        <li class="exclusion-attr" [class]="attribute.id" *ngIf="isCaptionAndValueTemplate(rating.attributes, attribute)">
                          <em>{{ attribute.caption }}: </em>{{ attribute.value }}
                        </li>

                        <!-- Percentage template-->
                        <li class="loading-attr" [class]="attribute.id" *ngIf="isPercentageLoadingTemplate(rating.attributes, attribute)">
                          <em>{{ attribute.caption }}: </em>{{ attribute.value }}&#37;
                        </li>

                        <!-- Permil template-->
                        <li
                          class="loading-permil-attr"
                          [class]="attribute.id"
                          *ngIf="isPerMilleLoadingTemplate(rating.attributes, attribute)"
                        >
                          <em>{{ attribute.caption }}: </em>{{ attribute.value }}&#x2030;
                        </li>

                        <!-- Duration template -->
                        <li
                          class="postpone-duration-attr"
                          [class]="attribute.id"
                          *ngIf="isDurationMonthTemplate(rating.attributes, attribute)"
                        >
                          <em>{{ attribute.caption }}: </em>{{ attribute.value }} {{ 'rspMonths' | translate }}
                        </li>

                        <!-- FlatExtraLoading template -->
                        <li class="loading-duration-attr" *ngIf="isFlatExtraLoadingWithDuration(rating.attributes, attribute)">
                          <em>{{ getFlatExtraLoadingWithDurationCaption(rating.attributes) }}&#x2030;</em>
                          -
                          <em>{{ getFlatExtraLoadingWithDurationValue(rating.attributes) }} {{ 'rspMonths' | translate }}</em>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </section>
              <section class="decision-default well-sm bg-info" *ngIf="result.ratings.length > 0">
                <p>
                  {{ 'rspResultDecisionDefault' | translate }}
                </p>
              </section>
            </section>
          </section>
        </div>
      </div>
      <div class="result-info" *ngIf="!showResultToUser" [innerHtml]="'rspResultDefaultInformationText' | translate"></div>
    </section>
    <div class="result-navigation d-flex justify-content-between">
      <button
        *ngIf="showGoToInitialInformationPageButton"
        id="back-btn"
        class="btn btn-secondary"
        (click)="goToInitialInformationPage()"
        type="button"
      >
        {{ 'rspResultButtonBackToInitialInformation' | translate }}
      </button>
      <a class="btn btn-primary float-end" href="{{ docUrl }}" download target="_blank" rel="noopener">
        {{ 'rspResultButtonDownloadPdf' | translate }}
      </a>
    </div>
  </div>
</div>
